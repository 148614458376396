<template>
  <CContainer class="min-vh-100" style="background-color: #ffffff;">

    <!-- Loaded user profile -->
    <div v-if="this.loadingMessage != ''">
      <div class="lds-ripple">
        <div></div>
      </div>
      <div class="row">
        <div class="col-12" style="text-align: center"> {{ this.loadingMessage }}</div>
      </div>
    </div>
    <div v-else-if="member == null && profile != null">
      <CRow>
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-5 mt-3"
          body-wrapper>
          <CAlert v-if="alert != ''" color="danger">{{ alert }}</CAlert>
          <CAlert v-if="success != ''" color="success">{{ success }}</CAlert>

          <img
            alt=""
            v-if="pictureUrl === ''"
            class="rounded-circle img-fluid"
            src="/img/human.png"
            width="50%"
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/4322/4322991.png';"
          />

          <img
            alt=""
            v-else
            class="rounded-circle img-fluid"
            :src="pictureUrl"
            width="180px"/>

          <h4 class="font-weight-normal mt-3">{{ displayName }}</h4>
          <h5 class="mb-3">กรอกข้อมูลเพื่อรับสิทธิพิเศษ</h5>
          <div v-if="profile != null">
            <strong class="text-danger mt-1 mb-3 font-weight-normal">
            {{ errors }}
            </strong>
            <CInput
              v-model="name"
              type="text"
              placeholder="ชื่อ-สกุล"
              class="form-control-md text-dark"
              add-input-classes="noborder"
              required="true"
              >
              <template #append>
                <span class="mx-icon-calendar">
                  <CIcon name="cil-user"/>
                </span>
              </template>
            </CInput>
            <CInput
              v-model="phone"
              type="tel"
              placeholder="เบอร์โทรฯ"
              required="true"
              add-input-classes="noborder">
              <template #append>
                <span class="mx-icon-calendar">
                  <CIcon name="cil-phone" />
                </span>
              </template>
            </CInput>
            <CInput
              v-model="email"
              type="email"
              placeholder="อีเมล"
              add-input-classes="noborder">
              <template #append>
                <span class="mx-icon-calendar">
                  <CIcon name="cib-gmail" />
                </span>
              </template>
            </CInput>

            <date-picker
              v-model="dateOfBirth"
              type="date"
              input-class="form-control noborder"
              style="width:100%;"
              format="DD/MM/YYYY"
              :editable=false
              :disabled-date="disabledRange"
              placeholder="เลือก วัน/เดือน/ปี เกิด">
            </date-picker>

            <hr class="mt-3" />
            <h6 @click="popupModal = true">เงื่อนไขและข้อตกลง</h6>
            <label>
              <input type="checkbox" v-model="condition" />
              <span class="font-weight-normal"> ยอมรับเงื่อนไขและข้อตกลง</span>
            </label>
            <p class="font-weight-normal text-center text-danger">
              {{ conalert }}
            </p>
            <CRow>
              <CCol col="12" v-if="loadingButton === true" class="pt-2">
                <CButton @click="createMember()" block color="success">
                  บันทึกข้อมูล
                </CButton>
              </CCol>
              <CCol col="12" v-else-if="loadingButton === false" class="pt-2">
                <CButton block color="light" disabled>
                  <CSpinner color="white" size="sm" />
                </CButton>
              </CCol>
            </CRow>
          </div> <!-- End if profile != null -->
          
          <!-- Loading profile is null-->
          <div v-else>
            <div class="lds-ripple">
              <div></div>
            </div>
            <div class="row">
              <div class="col-12" style="text-align: center"> {{ this.loadingMessage }}</div>
            </div>
          </div>

        </CCard>
      </CRow>

      <!-- Modal terms and condition -->
      <CModal
        :show.sync="popupModal"
        :footer="footer"
        centered
        size="md"
        title="เงื่อนไข และข้อตกลงการใช้บริการ"
        add-content-classes="modal-edited"
      >
        <CJumbotron class="p-3">
          <p style="font-size:10px">
            <strong>1 .</strong> ตรวจสอบการเข้าใช้โปรแกรม Silom POS
            และชื่อผู้ให้บริการให้ถูกต้อง
            โดยท่านสามารถเข้าใช้บริการได้ที่สถานประกอบการผู้ที่ให้ลงทะเบียน
            ผู้ให้บริการจะไม่รับผิดชอบต่อความผิดพลาด ในการเข้าใช้งานที่
            เกิดจากความผิดพลาดของท่าน
            <br />
            <strong>2 .</strong> ความถูกต้องของข้อมูล
            หรือรายละเอียดส่วนบุคคลของท่าน จะต้องเป็นความจริง
            และอาจพิสูจน์ถึงตัวตนของท่านได้ เราจะไม่รับผิดชอบต่อความเสียหาย
            สำหรับข้อมูลที่ไม่เป็นจริง
            <br />
            <strong>3 .</strong> รายละเอียดข้อมูล ชื่อ หรือ การติดต่อ
            ส่วนบุคคลจะต้องเป็นชื่อของเจ้าของผู้สมัครใช้บริการเท่านั้น
            เราจะไม่รับผิดชอบความเสียหาย
            ที่เกิดจากการแอบอ้างนำมาซึ่งข้อมูลของบุคคลอื่นเข้าใช้บริการ
            <br />
            <strong>4 .</strong> เพื่อไม่เป็นการละเมิดสิทธิ
            หรือลิขสิทธิ์ของบุคคลอื่น กรุณาใช้ข้อมูลในการสมัคร ที่เป็นของท่าน
            และไม่ผิดกฎหมาย
            <br />
            <strong>5 .</strong>
            การเข้าใช้บริการไม่ได้ไม่ว่ากรณีใดๆก็ตามซึ่งอาจเกิดจากการปรับปรุงระบบการให้บริการ
            ควาบกพร่องของเครือข่ายที่ให้บริการ เป็นต้น
            ท่านไม่อาจเรียกร้องความเสียหายใดๆ
            ซึ่งเกี่ยวด้วยการให้บริการดังกล่าวนี้ได้
            <br />
            <strong>6 .</strong> การยอมรับเงื่อนไขในการสมัครเข้าใช้บริการ
            หมายรวมถึงการที่ท่านอนุญาตให้ร้านค้าได้เข้าถึงข้อมูล
            และสำรองข้อมูลการติดต่อ รายชื่อเจ้าของจดหมายอิเลคทรอนิคส์ได้ โดยตลอด
            ทั้งนี้ โดยมิต้องสอบถามท่านอีกในแต่ละครั้งที่มีการเข้าถึง
            หรือสำรองข้อมูลในคราวถัดไป
          </p>
        </CJumbotron>

        <template #footer-wrapper>
          <CRow>
            <CCol>
              <div class="mx-auto text-center mb-4 mt-0">
                <label> 
                  <input
                    type="checkbox"
                    v-model="condition"
                    @click="popupModal = false"
                  />
                  <span class="font-weight-normal mt-2"> ยอมรับเงื่อนไขและข้อตกลง</span>
                </label>
              </div>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </div>

    <!-- Loading -->
    <div v-else>
      <div class="lds-ripple">
        <div></div>
      </div>
      <div class="row">
        <div class="col-12" style="text-align: center"> {{ this.loadingMessage }}</div>
      </div>
    </div>
  </CContainer>
</template>

<script>
import pos from '@/services/poscrm'
import liff from '@line/liff'
import moment from 'moment'
import MemberBar from './MemberBar'
import '@/util/loader.css'
import lineutil from '@/util/line'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    MemberBar,
    DatePicker,
  },
  data() {
    return {
      dateOfBirth: null,
      errors: '',
      name: '',
      email: null,
      phone: '',
      alert: '',
      loadingButton: true,
      success: '',
      member: null,
      yourpoint: 0,
      condition: '',
      popupModal: false,
      footer: '',
      conalert: '',
      userId: '',
      pictureUrl: '',
      displayName: '',
      profile: null,
      loadingMessage: 'Loading...'
    }
  },
  computed: {
    uid() {
      return this.$route.query.uid
    },
  },
  beforeCreate() {
    this.$store.dispatch('getShopLineOA')
  },
  created() {
    document.title = 'ข้อมูลสมาชิก'
    if (process.env.NODE_ENV === "development") {
      this.userId = process.env.VUE_APP_LINE_USER_ID
      this.displayName = 'TOEY'
      console.log('developement')
      console.log('process.env.VUE_APP_LINE_USER_ID', this.userId)
      this.loadingMessage = 'Loading user profile...'
      this.getUser()
    } else {
      liff.init({ liffId: process.env.VUE_APP_LIFF_ID_MEMBER_REGISTER }).then(async () => {
        if (liff.isInClient()) {
          if (!liff.isLoggedIn()) {
            this.loadingMessage = 'Loging In...'
            liff.login()
          }

          const accessToken = liff.getAccessToken()
          if (accessToken) {
            this.loadingMessage = 'Loading user profile...'

            const profile = await lineutil.getProfile(accessToken)
            this.profile = profile.data || {}
            localStorage.setItem('profile', JSON.stringify(this.profile))

            this.userId = this.profile.userId
            this.pictureUrl = this.profile.pictureUrl
            this.displayName = this.profile.displayName

            this.getUser()
          }
        } else {
          this.loadingMessage = 'Your browser does not support.'
        }
      })
    }
  },
  methods: {
    getUser() {
      const uid = this.uid
      const userId = this.userId || liff.getContext().userId
      this.loadingMessage = 'Loading user....'
      pos({
        method: 'get',
        url: '/api/v1.0/member/' + uid + '/getbyuserId/' + userId,
      }).then((response) => {
        this.loadingMessage = ''
        
        if (response.data.error.code === 0) {
          this.member = response.data.data.documents

          // Registered redirect to card view.
          if (this.member !== null) {
            // this.$router.push('/member/card?uid=' + this.uid)
            window.location.replace(
              '/member/card?uid=' + this.uid)
          }
        }
      }).catch((error) => {
        this.loadingMessage = error
        console.log(error)
      })
    },
    validateData() {
      if (this.name === '') {
        this.errors = 'กรุณากรอกชื่อ-สกุล'
      } else if (this.phone === '') {
        this.errors = 'กรุณากรอกเบอร์โทรฯ'
      } else if (!this.validatePhone(this.phone)) {
        this.errors = 'เบอร์โทรศัพท์ไม่ถูกต้อง'
      } else {
        this.errors = ''
      }

      if (this.condition !== true) {
        this.conalert = 'กรุณาคลิกยอมรับเงื่อนไขและข้อตกลง'
      } else {
        this.conalert = ''
      }

      if (this.errors === '' && this.conalert === '') {
        return true
      } else {
        return false
      }
    },
    createMember() {
      let uid = this.uid

      let userId = this.userId
      let pictureUrl = this.pictureUrl
      let displayName = this.displayName
      let dateOfBirth = moment(this.dateOfBirth).format('YYYY-MM-DD')

      let validate = this.validateData()

      if (validate === true) {
        let data = {
          userId: userId,
          avatar: pictureUrl,
          displayName: displayName,
          name: this.name,
          phone: this.phone,
          email: this.email,
          dateOfBirth: dateOfBirth,
          address: '',
          uid: uid,
          isMember: 'line',
          objectId: '',
        }

        this.loadingButton = false

        pos
          .post('/api/v1.0/member/' + uid + '/createcrm', data)
          .then((res) => {
            if (res.data.error.code != 0) {
              this.alert = res.data.error.message
              this.loadingButton = true
            } else {
              this.success = 'บันทึกสำเร็จ'
              // this.$router.push('/member/card?uid=' + this.uid)
              window.location.replace(
              '/member/card?uid=' + this.uid)
            }

            this.loadingButton = true
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    validEmail(email) {
      let EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return EMAILREG.test(email)
    },
    validatePhone(phone) {
      const MOBILEREG = /^[0]\d{9}$/
      return MOBILEREG.test(phone)
    },
    disabledRange: function (date) {
       return date > new Date();
    },
  },
}
</script>

<style>
.noborder {
  border-radius: 0%;
}
.empty {
  display: flex;
  text-align: center;
  min-height: 90vh;
}
</style>
